import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
// eslint-disable-next-line
export const FullWidthPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  previewContent,
}) => {
  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;
  if (images && images.smallImage) smallImage = images.smallImage;

  const showTitle = options.showTitle;

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            {smallImage ? (
              <figure className="image is-inline-block small-image is-pulled-right pt-0 mt-0 is-hidden-mobile">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: smallImage,
                    alt: String(title + " " + subtitle).trim(),
                    className: "small-image",
                  }}
                />
              </figure>
            ) : null}

            <div className="content">
              <DisplayContentHeader title={title} breadcrumbs={breadcrumbs} />
            </div>

            {previewContent ? (
              <>
                <div className="content content___full-width-page">
                  {previewContent}
                </div>
              </>
            ) : (
              <HTMLContent
                className="content content___full-width-page"
                content={body}
              />
            )}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

FullWidthPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const FullWidthPage = ({ data }) => {
  const { markdownRemark: post } = data;
  let { site, config } = data;

  config = config.frontmatter.site;
  site = site.siteMetadata.property;

  let siteUrl = site.url;
  let siteName = site.name;
  let sitePhone = "";

  if (config.url) siteUrl = config.url;
  if (config.name) siteName = config.name;
  if (config.phone) sitePhone = config.phone;

  let replacements = [];
  replacements["{siteurl}"] = siteUrl;
  replacements["{siteName}"] = siteName;
  replacements["{sitePhone}"] = sitePhone;

  if (config.location && config.location.length) {
    if (config.location[0] && config.location[0].address)
      replacements["{address_1}"] = config.location[0].address;
    if (config.location[1] && config.location[1].address)
      replacements["{address_2}"] = config.location[1].address;
    if (config.location[2] && config.location[2].address)
      replacements["{address_3}"] = config.location[2].address;
  }

  let html = post.html;

  if (replacements) {
    let keys = Object.keys(replacements);
    if (keys.length) {
      keys.forEach((key) => {
        html = html.replace(new RegExp(key, "g"), replacements[key]);
      });
    }
  }
  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  return (
    <Layout>
      <FullWidthPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
      />
    </Layout>
  );
};

FullWidthPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FullWidthPage;

export const fullWidthPageQuery = graphql`
  query FullWidthPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        summary
      }
    }
    site {
      siteMetadata {
        property {
          url
          name: siteName
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        site {
          url
          name
          phone
          location {
            address
          }
        }
      }
    }
  }
`;
